import React, { useEffect } from "react";
import {
  InstantSearch,
  useConfigure,
  useSearchBox,
} from "react-instantsearch-hooks-web";
import { useLocation } from "react-router";
import qs from "qs";
import { searchClient } from "app/components/Algolia/constants";
import { AlgoliaIndexes } from "../types";
import { InstructorResults } from "./InstructorResults";

function SearchConfig(): JSX.Element | null {
  const location = useLocation();
  const searchQuery =
    (qs.parse(location.search.slice(1)).query as string) || "";
  const { refine } = useSearchBox();

  // Configure search parameters
  useConfigure({
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore - hitsPerPage is a valid search parameter per Algolia docs: https://www.algolia.com/doc/api-reference/widgets/configure/react/
    hitsPerPage: 20,
  });

  // Set the search query
  useEffect(() => {
    refine(searchQuery);
  }, [searchQuery, refine]);

  return null;
}

export function InstructorSearch() {
  return (
    <InstantSearch
      indexName={AlgoliaIndexes.instructors}
      searchClient={searchClient}
    >
      <SearchConfig />
      <InstructorResults />
    </InstantSearch>
  );
}
