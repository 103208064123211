import React from "react";
import { useAccountMode } from "hooks/useAccountMode";
import { InstructorAvatar } from "app/components/InstructorAvatar";
import { useGetUserInstructorProfileBySlugPublicQuery } from "services/graphql";
import { LIBRARY_MODULE, ANALYTICS_LOCATION } from "constants/index";
import {
  librarySelectContent,
  LibrarySelectContentContainer,
  LibrarySelectContentContentType,
} from "services/typewriter/segment";
import { useHistory } from "react-router";
import styles from "./styles.module.scss";

export type InstructorData = {
  slug: string;
  listImagePath: string;
  name: string;
};

type InstructorCardProps = {
  instructorData: InstructorData;
};

export const InstructorCard = ({ instructorData }: InstructorCardProps) => {
  const { name, slug } = instructorData;
  const history = useHistory();
  const { accountMode } = useAccountMode();

  const { data } = useGetUserInstructorProfileBySlugPublicQuery({
    variables: { slug },
  });

  const instructor = data?.instructorBySlugPublic;
  const userProfile = instructor?.userProfile;
  const pathToPush = userProfile?.username
    ? `/@${userProfile.username}`
    : `/library/instructors/${slug}`;

  const userSelectsInstructor = () => {
    librarySelectContent({
      content_name: instructorData.name,
      content_type: LibrarySelectContentContentType.Instructor,
      content_id: instructorData.slug,
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_instructors,
      container: LibrarySelectContentContainer.Instructors,
      element_type: "instructor_card",
      featured: false,
      account_mode: accountMode,
    });

    history.push(pathToPush);
  };

  return (
    <div className={styles.wrapper} onClick={userSelectsInstructor}>
      <InstructorAvatar instructorSlug={slug} width={150} showName={false} />
      <h5 className={styles.name}>{userProfile?.name || name}</h5>
      {userProfile?.username && (
        <p className={styles.username}>@{userProfile.username}</p>
      )}
    </div>
  );
};
