import React from "react";
import {
  useInfiniteHits,
  useInstantSearch,
} from "react-instantsearch-hooks-web";
import { InstructorCard } from "app/components/InstructorCard";
import Flex from "app/components/Flex";
import { Waypoint } from "app/components/Waypoint";
import { ContentEmptyState } from "app/components/ContentEmptyState";

export function InstructorResults() {
  const { hits, isLastPage, showMore } = useInfiniteHits();
  const { status } = useInstantSearch();
  const isLoading = status === "loading";

  if (!hits) {
    return null;
  }

  if (hits.length <= 0) {
    return <ContentEmptyState />;
  }

  return (
    <Flex flexWrap="wrap" gap="16px">
      {hits.map(hit => (
        <InstructorCard
          instructorData={{
            slug: hit.slug as string,
            listImagePath: hit.listImagePath as string,
            name: hit.name as string,
          }}
          key={hit.objectID}
        />
      ))}
      {!isLoading && <Waypoint hasMore={!isLastPage} fetchData={showMore} />}
    </Flex>
  );
}
